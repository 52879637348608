import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "../../../node_modules/swiper/swiper.css";
// import "../../../node_modules/swiper/modules/pagination/pagination.min.css";

// import required modules
import { Autoplay } from "swiper";

const BannerOne = () => {
  return (
    <div className="banner banner-style-1">
      <div className="container">
        <div className="row align-items-end align-items-xl-start">
          <div className="col-lg-6">
            <div className="banner-content">
              <AnimationOnScroll
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}
              >
                <h1 className="title">Innovate. Code. Elevate.</h1>
                <div className="dudhat">
                  <span className="subtitle">
                    Making technology simple and easy to adapt and implement.
                    We provide Reliable custom solutions for -
                  <Swiper
                    direction={"vertical"}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                        <h4>Web development</h4>
                    </SwiperSlide>
                    <SwiperSlide>
                        <h4>Mobile app development</h4>
                    </SwiperSlide>
                    <SwiperSlide>
                        <h4>UI/UX Designing</h4>
                    </SwiperSlide>
                  </Swiper>
                  </span>
                </div>
                <button className="zoom_animation border-0 bg-white">
                <Link
                  to={process.env.PUBLIC_URL + "/contact"}
                  className="axil-btn btn-fill-primary btn-large"
                >
                  Get Started
                </Link>
                </button>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-thumbnail">
              <AnimationOnScroll
                animateIn="zoomIn"
                duration={2}
                delay={300}
                animateOnce={true}
              >
                <div className="large-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/images/banner/window.png"}
                    alt="Laptop"
                  />
                </div>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="slideInRight"
                duration={2}
                delay={300}
                animateOnce={true}
                style={{ backgroundColor: '', padding: '20px' , marginBottom: "-250px" }}
                className=""
              >
                <div className="large-thumb-2">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/banner/laptop-poses.png"
                    }
                    alt="Laptop"
                    height="fit-content"
                  />
                </div>
              </AnimationOnScroll>
              <ul className="list-unstyled shape-group">
                <li className="shape shape-1">
                  <AnimationOnScroll
                    animateIn="slideInLeft"
                    duration={1}
                    delay={800}
                    animateOnce={true}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/banner/chat-group.png"
                      }
                      alt="chat"
                    />
                  </AnimationOnScroll>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-21">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-4">
          <img
            src="/images/banner/angular.png"
            alt="angular"
            style={{minWidth: "25px"}}
          />
        </li>
        <li className="shape shape-5">
          <img src="/images/banner/reactjs.ico" alt="reactjs"  style={{minWidth: "25px"}}/>
        </li>
        <li className="shape shape-6">
          <img
            src="/images/banner/nodejs.png"
            alt="nodejs"
            style={{minWidth: "25px"}}
          />
        </li>
        <li className="shape shape-7">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-8">
          <img
            src="/images/banner/HTML5_logo.png"
            alt="HTML5"
            style={{minWidth: "30px"}}
          />
        </li>
      </ul>
    </div>
  );
};

export default BannerOne;
