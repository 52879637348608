import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addDoc, collection } from "firebase/firestore";
import Alert from "react-bootstrap/Alert";
import { db } from "../../firebase";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

// const FormTwo = () => {
//   const form = useRef();

//   const [formData, setFormData] = useState({
//     name: "",
//     mobile: "",
//     email: "",
//     message: "",
//   });

//   const [result, showresult] = useState(false);
//   const [loading, setLoading] = useState();

//   const sendEmail = async(e) => {
//     e.preventDefault();
//     setLoading(true)
//     console.log("Form ref ==>", form.current);
//     console.log("Form data ==>", formData);

//     // emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
//     //   .then((result) => {
//     //       console.log(result.text);
//     //   }, (error) => {
//     //       console.log(error.text);
//     //   });
//     //   form.current.reset();
//     //   showresult(true);
//     const ref = collection(db, "inquiry");
//     await addDoc(ref, {
//       ...formData,
//     });
//     setFormData({
//       name: "",
//       mobile: "",
//       email: "",
//       message: "",
//     });
//     showresult(true);
//     setLoading(false)
//     setTimeout(() => {
//         showresult(false);
//       }, 15000);
//     // emailjs
//     //   .sendForm(
//     //     "service_yj5dgzp",
//     //     "template_hfduayo",
//     //     form.current,
//     //     "WLENsTkBytC0yvItS"
//     //   )
//     //   .then(
//     //     (result) => {
//     //       console.log(result.text);
//     //     },
//     //     (error) => {
//     //       console.log(error.text);
//     //     }
//     //   );
//     // form.current.reset();
//     // showresult(true);
//   };

//   return (
//     <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
//       <div className="form-group">
//         <label>Name</label>
//         <input
//           type="text"
//           className="form-control"
//           name="contact-name"
//           required
//           value={formData.name}
//           onChange={(e) => {
//             setFormData({ ...formData, name: e.target.value });
//           }}
//         />
//       </div>
//       <div className="form-group">
//         <label>Email</label>
//         <input
//           type="email"
//           className="form-control"
//           name="contact-email"
//           required
//           value={formData.email}
//           onChange={(e) => {
//             setFormData({ ...formData, email: e.target.value });
//           }}
//         />
//       </div>
//       <div className="form-group">
//         <label>Phone</label>
//         <input
//           type="tel"
//           className="form-control"
//           name="contact-phone"
//           required
//           value={formData.mobile}
//           onChange={(e) => {
//             setFormData({ ...formData, mobile: e.target.value });
//           }}
//         />
//       </div>
//       <div className="form-group mb--40">
//         <label>How can we help you?</label>
//         <textarea
//           className="form-control"
//           name="contact-message"
//           rows="4"
//           value={formData.message}
//           onChange={(e) => {
//             setFormData({ ...formData, message: e.target.value });
//           }}
//         ></textarea>
//       </div>
//       <div className="form-group">
//         <button
//           type="submit"
//           className="axil-btn btn-fill-primary btn-fluid btn-primary"
//           name="submit-btn"
//           disabled={loading}
//         >
//           {loading ? "Please wait" : "Submit"}
//         </button>
//       </div>
//       <div className="form-group">{result ? <Result /> : null}</div>
//     </form>
//   );
// };

const FormTwo = () => {
  const [result, showResult] = useState(false);
  const [loading, setLoading] = useState(false);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test('email', 'Please enter a valid email address', (value) => {
        // Additional email validation
        if (!value) return false;
        
        // Check for consecutive dots
        if (value.includes('..')) return false;
        
        // Check for valid characters before and after @
        const [local, domain] = value.split('@');
        if (local.startsWith('.') || local.endsWith('.')) return false;
        if (domain && (domain.startsWith('.') || domain.endsWith('.'))) return false;
        
        return true;
      }),
    mobile: Yup.string()
      .test('is-numeric', 'Phone number must contain only numeric characters', 
        (value) => {
          // Check if the string contains only numeric characters
          return value ? /^\d+$/.test(value) : false;
        }
      )
      .min(8, "Phone number must be at least 8 digits")
      .max(15, "Phone number cannot exceed 15 digits")
      .required("Phone number is required"),
    message: Yup.string().required("Message is required")
  });

  // Initial form values
  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    message: ""
  };

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    
    try {
      // Add document to Firestore
      const ref = collection(db, "inquiry");
      await addDoc(ref, {
        ...values
      });

      // Show success result
      showResult(true);
      resetForm();

      // Hide result after 15 seconds
      setTimeout(() => {
        showResult(false);
      }, 15000);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className="axil-contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Field 
              type="text" 
              name="name" 
              className="form-control"
              required 
              autoComplete="off"
            />
            <ErrorMessage 
              name="name" 
              component="div" 
              className="text-danger small mt-1" 
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Field 
              type="email" 
              name="email" 
              className="form-control"
              required 
              autoComplete="off"
            />
            <ErrorMessage 
              name="email" 
              component="div" 
              className="text-danger small mt-1" 
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobile">Phone</label>
            <Field 
              type="tel" 
              name="mobile" 
              className="form-control"
              required 
              onChange={(e) => {
                // Remove non-numeric characters
                const numericValue = e.target.value.replace(/[^\d]/g, '');
                setFieldValue('mobile', numericValue);
              }}
              autoComplete="off"
            />
            <ErrorMessage 
              name="mobile" 
              component="div" 
              className="text-danger small mt-1" 
            />
          </div>

          <div className="form-group mb--40">
            <label htmlFor="message">How can we help you?</label>
            <Field 
              as="textarea" 
              name="message" 
              className="form-control" 
              rows="4"
              required 
              autoComplete="off"
            />
            <ErrorMessage 
              name="message" 
              component="div" 
              className="text-danger small mt-1" 
            />
          </div>

          <div className="form-group">
            <button
              type="submit"
              className="axil-btn btn-fill-primary btn-fluid btn-primary"
              disabled={isSubmitting || loading}
            >
              {loading || isSubmitting ? "Please wait" : "Submit"}
            </button>
          </div>

          <div className="form-group">
            {result ? <Result /> : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};


export default FormTwo;
