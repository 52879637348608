import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

import greview from "../assets/image/Google_review.svg"
import gdreview from "../assets/image/Glassdoor_review.svg"
import creview from "../assets/image/Clutch_review.svg"
import seoData from "../seoData/MobileAppDevelopment.json"
// import argumentTeam from "../assets/image/services/argumentTeam.svg"
// import liIcon from "../li-icon.svg"

const MobileAppDevelopment = () => {
  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Mobile App Development" page="Mobile App Development" />

        <div className="section section-padding pb-0">
          <div className="container">
            <div className="row">
              <p className="mb-3">
                Looking for the best mobile app development company that can
                help turn your business ideas Into successful Reality?
              </p>
              <p className="mb-3">
                For full-stack app development, hire our experienced mobile app
                developers. This will enhance your brand visibility and sales.
                We have designed and developed over 1200 Android and iOS-based
                mobile applications from scratch. Want to get your free quote?
              </p>
            </div>
            <section id="augmentation-card">
              <div className="py-4">
                <div className="section-header">
                  <h5 className="mb-3">Top Mobile App Development</h5>
                  <p>
                    We provide complete Custom mobile application development
                    design, integration, and management services in USA. Whether
                    it's a consumer-oriented app or a transformative
                    enterprise-level solution, the company manages the entire
                    mobile app development process, from concept to delivery,
                    and ongoing support and delivers quality solutions that work
                    on Android, iOS, or cross-platform.
                  </p>
                  <p>
                    Making an app with the capability of earning billions,
                    requires smart and extra effort. We ensure that our app
                    development services align with your vision and achieve it
                    within a fixed budget and timeframe. Bring your unique idea
                    to us and watch it grow splendidly with our team.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-11 col-lg-9 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      1. Android App Development
                    </div>
                    <p className="mb-0 mt-3">
                      Your custom Android app needs to be unique to stand out
                      from the 3 million + apps in Google Play Store. With our
                      Android app development services, your app will be
                      equipped with a range of features and UI/UX that will
                      enhance the user experience.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      2. iOS App Development
                    </div>
                    <p className="mb-0 mt-3">
                      Our iOS app development process helps you build an mobile
                      app compatible with all the devices and services that
                      Apple offers. Our iOS app development services cover all
                      aspects of the app development process, right from the
                      ideation to the launch.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      3. React Native Cross Platform App Development
                    </div>
                    <p className="mb-0 mt-3">
                      The expertise of ITCODE Infotech's team, combined with the
                      perks of React Native app development, forms a recipe for
                      the perfect mobile application solution. This combination
                      results in effective cross-platform app development for
                      Android and iOS that gives you an edge above your
                      competition.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      4. Flutter Cross Platform App Development
                    </div>
                    <p className="mb-0 mt-3">
                      With Flutter app development, your app will have a native
                      look and feel that your users will cherish. Our mobile app
                      development services will further facilitate the Flutter
                      app development process to create a high-quality app for
                      Android and iOS users.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      5. Mobile App Support & Maintenance
                    </div>
                    <p className="mb-0 mt-3">
                      We know building an application alone is not all. Timely
                      updates & maintenance for a seamless user experience are
                      equally important. We can provide complete Mobile app
                      support and maintenance services while keeping your app
                      updated with the latest versions and features.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      6. Mobile App Testing
                    </div>
                    <p className="mb-0 mt-3">
                      We offer unmatched and performance-oriented mobile app
                      testing services. Whether it is automated testing or
                      manual testing, with ITCODE Infotech, the quality,
                      usability, and security of your mobile app will be at the
                      highest level possible.
                    </p>
                  </div>
                </div>
              </div>
              <div className="rate-row">
            <div
              className="row align-items-center text-center aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="col-12 col-lg-12">
                <div className="review-h4">
                  <h4>Read our reviews on:</h4>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="review">
                  <img
                    src={greview}
                    className=" ls-is-cached lazyloaded"
                    alt="review"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="review review-border">
                  <img
                    src={gdreview}
                    className=" ls-is-cached lazyloaded"
                    alt="review"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="review">
                  <img
                    src={creview}
                    className=" ls-is-cached lazyloaded"
                    alt="review"
                  />
                </div>
              </div>
            </div>
          </div>
            </section>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default MobileAppDevelopment;
